// @flow

import axios from "axios";
import { baseURL } from "../config";

const client = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  },
});

export const fetchOrders = async (
  status: string,
  date: {
    from: string,
    to: string,
  },
  page: number,
  limit: number
) => {
  try {
    let response = await client.get(
      `/api/admin/orders?page=${page}&paginate=${limit}&status=${status}&from=${date.from}&to=${date.to}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOrderHistory = async (order_id: number) => {
  try {
    let response = await axios.get(`/api/admin/orders/${order_id}/status`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSingleOrder = async (order_id: number) => {
  try {
    let response = await client.get(`/api/admin/orders/${order_id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStatus = async (order_id, status, payload) => {
  try {
    let response = await client.patch(
      `/api/admin/orders/${order_id}/${status}`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const paymentDisputeService = async (data) => {
  try {
    const response = await client.post(
      `/api/admin/orders/${data.order_id}/transactions`,
      data
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
