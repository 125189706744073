/* eslint-disable */
// @flow

import React from "react";
import { useStyletron } from "baseui";
import * as Search from "js-search";
import { Input } from "baseui/input";
import {
  Table,
  TableContainer,
  Th,
  Td,
  NameContainer,
} from "../operators/styled-components";
import { Label3, Paragraph3 } from "baseui/typography";
import { Button, KIND, SIZE as ButtonSize } from "baseui/button";
import Show from "baseui/icon/show";
import Delete from "baseui/icon/delete";
import ContentDrawer from "../single-operator/content-drawer";
import { Tabs, Tab } from "baseui/tabs-motion";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../qui/loader";
import { getPrices, removePrices } from "../products/actions";
import Details from "./details";
import EditDetail from "./edit";
import { set } from "date-fns";
import { Select } from "baseui/select";

type Props = {
  prices: any[],
  pricing_type: string,
};

const TABLE_HEADER = [
  { label: "Type", id: "type_id" },
  { label: "Base Distance", id: "base_distance" },
  { label: "Min Distance", id: "min_distance" },
  { label: "Max Distance", id: "max_distance" },
  { label: "List Base Price", id: "list_base_price" },
  { label: "List Unit Price", id: "list_unit_price" },
  { label: "Cost Base Price", id: "cost_base_price" },
  { label: "Cost Unit Price", id: "cost_unit_price" },
];

function PriceTable(props: Props) {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState("0");
  const [priceId, setPriceId] = React.useState(null);
  const [query, setQuery] = React.useState("");
  const [productFilter, setProductFilter] = React.useState([]);
  const [productId, setProductId] = React.useState(null);
  const [listings, setListings] = React.useState([]);

  const { price, sideLoading, close, products } = useSelector(
    (state) => state.product
  );

  const dispatch = useDispatch();

  function deletePrice() {
    dispatch(removePrices(price.id));
  }

  const search = new Search.Search("id");

  search.addIndex("type_id");
  search.addIndex(["product", "body_html"]);
  search.addIndex(["product", "display_name"]);
  search.addIndex(["product", "description"]);
  search.addIndex(["product", "body_html"]);

  search.addDocuments(props.prices);

  const results = search.search(query);

  function renderPriceContent() {
    return (
      <>
        {sideLoading ? (
          <Loader />
        ) : (
          <div>
            <Tabs
              activeKey={activeKey}
              activateOnFocus
              onChange={({ activeKey: key }) => setActiveKey(key)}
            >
              <Tab title="Details">
                <Details price={price} />
                <hr />
                {price.id && (
                  <Button
                    className="pick-file"
                    onClick={deletePrice}
                    $style={{
                      borderRadius: "4px",
                      background: "#ff4646",
                      cursor: "pointer",
                      ":hover": {
                        background: "#ff4646",
                        opacity: "0.8",
                      },
                    }}
                    // size="compact"
                    target="_blank"
                    startEnhancer={Delete}
                  >
                    Delete Price
                  </Button>
                )}
              </Tab>
              <Tab title="Edit">
                <EditDetail pricing_type={props.pricing_type} />
              </Tab>
            </Tabs>
          </div>
        )}
      </>
    );
  }

  React.useEffect(() => {
    if (!isOpen) {
      setPriceId(null);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (priceId) {
      dispatch(getPrices(priceId));
    }
  }, [priceId]);

  function openDrawer(price_id) {
    setIsOpen(true);
    setPriceId(price_id);
  }

  React.useEffect(() => {
    if (close) {
      setIsOpen(false);
    }
  }, [close]);

  React.useEffect(() => {
    if (productFilter && productFilter.length === 1) {
      setListings(
        props.prices.filter((item) => item.product_id === productFilter[0].id)
      );
    } else {
      setListings(props.prices);
    }
  }, [productFilter]);

  React.useEffect(() => {
    if (query.length === 0) {
      setListings(props.prices);
    } else {
      setListings(results);
    }
  }, [query]);

  return (
    <>
      <ContentDrawer
        renderContent={renderPriceContent}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
      />
      {/* Filter container */}
      <div
        className={css({
          display: "flex",
          justifyContent: "left",
          flexWrap: "wrap",
          margin: "4px 0px",
        })}
      >
        <div
          className={css({
            marginRight: "4px",
          })}
        >
          <Input
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            clearable
            clearOnEscape
            placeholder="Search price list"
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  width: "320px",
                  borderRadius: "4px",
                }),
              },
            }}
          />
        </div>
        <div
          className={css({
            marginLeft: "4px",
          })}
        >
          <Select
            options={
              products &&
              products.map((item) => {
                return {
                  label: item.display_name,
                  id: item.id,
                };
              })
            }
            value={productFilter}
            onChange={({ value }) => {
              setProductFilter(value);
            }}
            placeholder="Filter by product"
            labelKey="label"
            valueKey="id"
            type="select"
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  width: "320px",
                  borderRadius: "4px",
                }),
              },
            }}
          />
        </div>
      </div>
      {/* End of filter container */}
      <TableContainer>
        <Table>
          <tr>
            {props.prices &&
              TABLE_HEADER.map((item, idx) => {
                return (
                  <Th key={idx}>
                    <div
                      className={css({
                        display: "flex",
                        maxWidth: "200px",
                      })}
                    >
                      <Label3>{item.label}</Label3>
                    </div>
                  </Th>
                );
              })}
          </tr>
          {listings &&
            listings.map((item, idx) => {
              return (
                <tr key={idx}>
                  <Td>
                    <NameContainer>
                      <Paragraph3>{item.product.display_name}</Paragraph3>
                      <Button
                        kind={KIND.tertiary}
                        startEnhancer={Show}
                        size={ButtonSize.compact}
                        onClick={() => openDrawer(item.id)}
                      >
                        view
                      </Button>
                    </NameContainer>
                  </Td>
                  <Td>
                    <Paragraph3>{item.base_distance} Km</Paragraph3>
                  </Td>
                  <Td>
                    <Paragraph3>{item.min_distance} Km</Paragraph3>
                  </Td>
                  <Td>
                    <Paragraph3>{item.max_distance} Km</Paragraph3>
                  </Td>
                  <Td>
                    <Paragraph3>KES.{item.list_base_price}</Paragraph3>
                  </Td>
                  <Td>
                    <Paragraph3>KES.{item.list_unit_price}</Paragraph3>
                  </Td>
                  <Td>
                    <Paragraph3>KES.{item.cost_base_price}</Paragraph3>
                  </Td>
                  <Td>
                    <Paragraph3>KES.{item.cost_unit_price}</Paragraph3>
                  </Td>
                </tr>
              );
            })}
        </Table>
      </TableContainer>
    </>
  );
}

export default PriceTable;
