import React from "react";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Avatar } from "baseui/avatar";
import { Button } from "baseui/button";
import { Tabs, Tab } from "baseui/tabs-motion";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import ArrowLeft from "baseui/icon/arrow-left";
import OrderStatus from "./order-status";
import { DetailsContainer, OrderDetails } from "./styled-components";
import {
  Label1,
  ParagraphSmall,
  ParagraphMedium,
  Label2,
  Label4,
  ParagraphXSmall,
  Paragraph3,
} from "baseui/typography";
import Loader from "../qui/loader";
import { useStyletron } from "baseui";
import { fetchCarriersAction } from "../operators/actions";
import { fetchVehiclesActions } from "../vehicles/actions";
import { capitalizer } from "../helpers/string";
import { useState } from "react";
import PaymentDispute from "./payment-dispute";
import Transactions from "./transactions";
import { fetchSingleOrderAction, syncOrder } from "./actions";
import { getDateDiffInMins } from "../helpers/date";
import OrderHistory from "./order-history";
import { orderDurationChecker } from "../helpers/order-duration";
import OrderAssignments from "./order-assignments";
import OrderUploads from "./order-uploads";

export default function SingleOrder() {
  const {
    order,
    sideLoading,
    history: orderStatusHistory,
  } = useSelector((state) => state.order);
  const [activeKey, setActiveKey] = useState("0");
  const params = useParams();

  const [css] = useStyletron();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (order?.id) {
      dispatch(
        fetchCarriersAction(null, 1, 5000, null, "is_driver=true", null, null)
      );
      dispatch(fetchVehiclesActions(1, 5000, "", ""));
    }
  }, [order]);

  React.useEffect(() => {
    if (params.order_id) {
      dispatch(fetchSingleOrderAction(params.order_id));
    }
  }, []);

  React.useEffect(() => {
    let interval;

    if (params.order_id) {
      interval = setInterval(() => {
        dispatch(syncOrder(params.order_id));
      }, 5000);
    }

    return () => clearInterval(interval);
  }, []);

  const history = useHistory();

  return (
    <DetailsContainer>
      <Button
        startEnhancer={ArrowLeft}
        onClick={() => history.push(`/orders?currentFilter=0`)}
        className={css({
          background: "none !important",
          color: "#ffa020 !important",
          ":hover": {
            background: "#e4e4e4 !important",
          },
        })}
      >
        Back to orders
      </Button>
      <OrderDetails
        className={css({
          padding: "20px 0px",
        })}
      >
        {sideLoading && !order?.shipper ? (
          <Loader />
        ) : (
          <>
            <section>
              <section
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                })}
              >
                <Label1>{order?.order_number}</Label1>
                <section>
                  <Label1
                    $style={{
                      "@media only screen and (max-width: 523px)": {
                        fontSize: "14px !important",
                      },
                    }}
                  >
                    Order Status: {capitalizer(order?.order_status_id)}
                  </Label1>
                  <Label1
                    $style={{
                      "@media only screen and (max-width: 523px)": {
                        fontSize: "14px !important",
                      },
                    }}
                  >
                    Payment: {capitalizer(order?.invoice_status_id)}
                  </Label1>
                </section>
              </section>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "4px 0px",
                })}
              >
                <div className={css({})}>
                  <div
                    className={css({
                      minWidth: "1fr",
                    })}
                  >
                    <ParagraphSmall>
                      Pickup Date{" "}
                      {moment(
                        momentTimezone.tz(
                          order?.order_origins &&
                            order?.order_origins[0]?.pickup_date,
                          momentTimezone.tz.guess()
                        )
                      ).format("lll")}
                    </ParagraphSmall>
                  </div>
                </div>
                <div>
                  {/* <div
                    className={css({
                      minWidth: "1fr",
                    })}
                  >
                    <ParagraphSmall $style={{ textAlign: "right" }}>
                      {moment(
                        momentTimezone.tz(
                          (order?.order_origins &&
                            order?.order_origins[0]?.pickup_date) ||
                            order?.date_order,
                          momentTimezone.tz.guess()
                        )
                      ).format("lll")}
                    </ParagraphSmall>
                  </div> */}
                </div>
              </div>

              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "4px 0px",
                  flexWrap: "wrap",
                })}
              >
                <div
                  className={css({
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    "@media only screen and (max-width: 523px)": {
                      flexDirection: "row",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxOrient: "horizontal",
                      WebkitBoxDirection: "normal",
                      alignItems: "left",
                      WebkitBoxAlign: "left",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "8px",
                      fontSize: "8px !important",
                    },
                  })}
                >
                  <Label1
                    $style={{
                      "@media only screen and (max-width: 523px)": {
                        fontSize: "14px !important",
                      },
                    }}
                  >
                    Vehicle: {order?.order_lines && order?.order_lines[0].name}
                  </Label1>
                </div>
                <div>
                  <div>
                    <div
                      className={css({
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: `repeat(${1} 1fr)`,
                        gridColumnGap: "0px",
                        gridRowGap: "0px",
                      })}
                    >
                      <div
                        className={css({
                          display: "flex",
                          placeItems: "start",
                        })}
                      >
                        <div className={css({ marginLeft: "15px" })}>
                          <div
                            className={css({
                              display: "grid",
                              gridTemplateColumns: "18px 1fr",
                              gridTemplateRows:
                                "minmax(18px, 34px) 1fr minmax(18px, 34px)",
                              gridColumnGap: "8px",
                              gridRowGap: "9px",
                            })}
                          >
                            <svg
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              className={css({
                                flex: "0 0 auto",
                              })}
                            >
                              <circle
                                cx="12"
                                cy="12"
                                fill="black"
                                r="9"
                              ></circle>
                              <circle
                                cx="12"
                                cy="12"
                                fill="white"
                                r="3"
                              ></circle>
                            </svg>
                            <section>
                              <ParagraphMedium $style={{ margin: "0px" }}>
                                {order?.order_origins &&
                                  order?.order_origins[0]?.address.split(
                                    ","
                                  )[0]}
                              </ParagraphMedium>
                              <ParagraphXSmall $style={{ margin: "0px" }}>
                                {order?.order_origins &&
                                  order?.order_origins[0]?.address
                                    .split(",")
                                    .splice(
                                      1,
                                      order?.order_origins[0]?.address.split(
                                        ","
                                      ).length - 2
                                    )
                                    .join()}
                              </ParagraphXSmall>
                            </section>
                            <div
                              className={css({
                                height: "100%",
                                width: "100%",
                                minHeight: "40px",
                                display: "flex",
                                justifyContent: "center",
                              })}
                            >
                              <div
                                className={css({
                                  height: "100%",
                                  width: "2px",
                                  background: "#000",
                                  minHeight: "40px",
                                })}
                              ></div>
                            </div>
                            <div
                              className={css({
                                height: "1fr",
                                width: "2px",
                                background: "transparent",
                              })}
                            ></div>
                            <svg
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              className={css({
                                flex: "0 0 auto",
                              })}
                            >
                              <rect
                                fill="black"
                                height="18"
                                width="18"
                                jlkajdflkajdfa
                                x="3"
                                y="3"
                              ></rect>
                              <rect
                                fill="white"
                                height="6"
                                width="6"
                                x="9"
                                y="9"
                              ></rect>
                            </svg>
                            <section>
                              <ParagraphMedium $style={{ margin: "0px" }}>
                                {order?.order_destinations &&
                                  order?.order_destinations[0]?.address.split(
                                    ","
                                  )[0]}
                              </ParagraphMedium>
                              <ParagraphXSmall $style={{ margin: "0px" }}>
                                {order?.order_destinations &&
                                  order?.order_destinations[0]?.address
                                    .split(",")
                                    .splice(
                                      1,
                                      order?.order_destinations[0]?.address.split(
                                        ","
                                      ).length - 2
                                    )
                                    .join()}
                              </ParagraphXSmall>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className={css({
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  "@media only screen and (max-width: 523px)": {
                    flexDirection: "row",
                    boxSizing: "border-box",
                    display: "flex",
                    WebkitBoxOrient: "horizontal",
                    WebkitBoxDirection: "normal",
                    alignItems: "left",
                    WebkitBoxAlign: "left",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "8px",
                    fontSize: "8px !important",
                  },
                })}
              >
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      fontSize: "14px !important",
                    },
                  }}
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency:
                      (order?.order_lines &&
                        order?.order_lines[0]?.currency_code) ||
                      "KES",
                  }).format(
                    order?.order_lines && order?.order_lines[0]?.price_total
                  )}
                </Label1>
              </div> */}
              <div
                className={css({
                  boxSizing: "border-box",
                  // display: "flex",
                  // justifyContent: "left",
                  marginTop: "16px",
                  width: "1fr",
                  "@media only screen and (max-width: 523px)": {
                    flexDirection: "row",
                    boxSizing: "border-box",
                    display: "flex",
                    WebkitBoxOrient: "horizontal",
                    WebkitBoxDirection: "normal",
                    alignItems: "left",
                    WebkitBoxAlign: "left",
                    justifyContent: "left",
                    width: "100%",
                    marginTop: "16px",
                    fontSize: "8px !important",
                    flexWrap: "wrap",
                  },
                })}
              >
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      width: "100%",
                      fontSize: "14px !important",
                    },
                  }}
                >
                  Total amount:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency:
                      (order.order_lines &&
                        order.order_lines[0]?.currency_code) ||
                      "KES",
                  }).format(order.total_price)}
                </Label1>
                <br />
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      width: "100%",
                      fontSize: "14px !important",
                    },
                  }}
                >
                  Paid:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency:
                      (order.order_lines &&
                        order.order_lines[0]?.currency_code) ||
                      "KES",
                  }).format(
                    order.order_lines &&
                      parseInt(order.order_lines[0]?.price_total, 10) -
                        parseInt(
                          order.order_lines && order.total_outstanding,
                          10
                        )
                  )}
                </Label1>
                <br />
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      width: "100%",
                      fontSize: "14px !important",
                    },
                  }}
                >
                  Outstanding:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency:
                      (order.order_lines &&
                        order.order_lines[0]?.currency_code) ||
                      "KES",
                  }).format(order.order_lines && order.total_outstanding)}
                </Label1>
                <br />
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      width: "100%",
                      fontSize: "14px !important",
                    },
                  }}
                >
                  Payment Status: {capitalizer(order?.invoice_status_id)}
                </Label1>
                <br />
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      width: "100%",
                      fontSize: "14px !important",
                    },
                  }}
                >
                  Percentage Paid:{" "}
                  {parseFloat(
                    ((parseInt(order.total_price, 10) -
                      parseInt(order.total_outstanding, 10)) /
                      parseInt(order.total_price, 10)) *
                      100
                  ).toFixed(2)}
                  %
                </Label1>
              </div>
              <br />
              <Label1
                $style={{
                  "@media only screen and (max-width: 523px)": {
                    fontSize: "14px !important",
                  },
                }}
              >
                <span>Placed on: </span>
                {moment(
                  momentTimezone.tz(
                    order?.created_at,
                    momentTimezone.tz.guess()
                  )
                ).format("lll")}
              </Label1>
              <br />
              <Label1
                $style={{
                  "@media only screen and (max-width: 523px)": {
                    fontSize: "14px !important",
                  },
                }}
              >
                <span>Pickup date: </span>
                {moment(
                  momentTimezone.tz(
                    order?.order_origins &&
                      order?.order_origins[0]?.pickup_date,
                    momentTimezone.tz.guess()
                  )
                ).format("lll")}
              </Label1>
              <br />
              {(order.order_status_id === "delivered" ||
                order.order_status_id === "cancelled") && (
                <Label1
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      fontSize: "14px !important",
                    },
                  }}
                >
                  <span>Duration:</span>
                  {orderDurationChecker(order)?.text} [
                  {orderDurationChecker(order)?.message}]
                </Label1>
              )}
              <br />
              {order?.order_destinations &&
                order?.order_destinations[0].dropoff_date && (
                  <Label1
                    $style={{
                      "@media only screen and (max-width: 523px)": {
                        fontSize: "14px !important",
                      },
                    }}
                  >
                    <span>Delivered date: </span>
                    {moment(
                      momentTimezone.tz(
                        order?.order_destinations[0].dropoff_date,
                        momentTimezone.tz.guess()
                      )
                    ).format("lll")}
                  </Label1>
                )}
            </section>
            <section
              className={css({
                flexDirection: "column",
                justifyContent: "flex-start",
                display: "flex",
              })}
            >
              <Label1
                $style={{
                  "@media only screen and (max-width: 523px)": {
                    fontSize: "14px !important",
                  },
                }}
              >
                Commodity:
              </Label1>
              <Label1
                $style={{
                  "@media only screen and (max-width: 523px)": {
                    fontSize: "14px !important",
                  },
                }}
              >
                {order?.order_loads && order?.order_loads[0].commodity}
              </Label1>
            </section>
            <section
              className={css({
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
                borderBottom: "1px solid #dcdada",
                borderTop: "1px solid #dcdada",
                flexWrap: "wrap",
                "@media (max-width: 600px)": {
                  justifyContent: "left",
                },
              })}
            >
              <section
                className={css({
                  padding: "8px 0px",
                })}
              >
                <Label1>Shipper</Label1>
                <section
                  className={css({
                    display: "flex",
                    placeItems: "center",
                  })}
                >
                  {order?.shipper && (
                    <Avatar
                      name={order?.shipper?.name}
                      size={"64px"}
                      key={order?.shipper?.email}
                      overrides={{
                        Root: {
                          style: ({ $theme }) => ({
                            backgroundColor: "#ffa020",
                          }),
                        },
                      }}
                    />
                  )}
                  <section
                    className={css({
                      marginLeft: "8px",
                    })}
                  >
                    <Label2>{order?.shipper?.name}</Label2>
                    <Label4>{order?.shipper?.phone}</Label4>
                  </section>
                </section>
              </section>
            </section>

            <section
              className={css({
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
                borderBottom: "1px solid #dcdada",
                borderTop: "1px solid #dcdada",
                flexWrap: "wrap",
                "@media (max-width: 600px)": {
                  justifyContent: "left",
                },
              })}
            >
              {order?.driver_id && (
                <section
                  className={css({
                    padding: "8px 0px",
                  })}
                >
                  <Label1>Driver Assigned</Label1>
                  <section
                    className={css({
                      display: "flex",
                      placeItems: "center",
                    })}
                  >
                    <Avatar
                      name={order?.driver.name}
                      size={"64px"}
                      key={order?.driver_id}
                    />
                    <section
                      className={css({
                        marginLeft: "8px",
                      })}
                    >
                      <Label2>{order?.driver.name}</Label2>
                      <Label4>{order?.driver.phone}</Label4>
                    </section>
                  </section>
                </section>
              )}
              {order?.vehicle_id && (
                <section
                  className={css({
                    padding: "8px 0px",
                  })}
                >
                  <Label1>Vehicle Assigned</Label1>
                  <section
                    className={css({
                      display: "flex",
                      placeItems: "center",
                    })}
                  >
                    {order?.order_lines.length > 0 && (
                      <img
                        src={
                          order.order_lines[0].name === "Motorcycle"
                            ? "https://res.cloudinary.com/quatrix/image/upload/v1630308043/assets/web/vehicles/v3/bike.svg"
                            : order.order_lines[0].name === "car"
                            ? "https://res.cloudinary.com/quatrix/image/upload/v1630308041/assets/web/vehicles/v3/car.svg"
                            : order.order_lins[0].name === "pickup"
                            ? "https://res.cloudinary.com/quatrix/image/upload/v1630308041/assets/web/vehicles/v3/pickup.svg"
                            : order.order_lines[0].name === "van"
                            ? "https://res.cloudinary.com/quatrix/image/upload/v1630308041/assets/web/vehicles/v3/small_van.svg"
                            : order.order_lines[0].name === "foot"
                            ? "https://res.cloudinary.com/quatrix/image/upload/v1630308044/assets/web/vehicles/v3/walker.svg"
                            : "https://res.cloudinary.com/quatrix/image/upload/v1630308038/assets/web/vehicles/v3/5T.svg"
                        }
                        className={css({
                          width: "70px",
                          margin: "0px",
                        })}
                        alt=""
                      />
                    )}
                    <section
                      className={css({
                        marginLeft: "8px",
                      })}
                    >
                      <Label2>{order?.vehicle.licence_plate}</Label2>
                    </section>
                  </section>
                </section>
              )}
            </section>
            <Tabs
              activeKey={activeKey}
              onChange={({ activeKey }) => {
                setActiveKey(activeKey);
              }}
              activateOnFocus
            >
              {order?.order_status_id !== "delivered" && (
                <Tab title="Order Update">
                  <OrderStatus />
                </Tab>
              )}
              <Tab title="Order History">
                <OrderHistory />
              </Tab>
              {order?.invoice_status_id !== "paid" && (
                <Tab title="Payment Processing">
                  <PaymentDispute order={order} />
                </Tab>
              )}
              {order?.order_transactions && (
                <Tab title="Transactions">
                  <Transactions transactions={order?.order_transactions} />
                </Tab>
              )}
              <Tab title="Notes">
                <Paragraph3>{order?.note}</Paragraph3>
                <Paragraph3>
                  Pickup Notes:
                  {order && order.id && order?.order_origins[0]?.note}
                </Paragraph3>
                <Paragraph3>
                  Dropoff Notes:
                  {order && order.id && order?.order_destinations[0]?.note}
                </Paragraph3>
              </Tab>
              <Tab title="Tracking & Contacts">
                {order?.order_status_id !== "delivered" && (
                  <>
                    <Link
                      className={css({
                        color: "#0c9cee",
                        textDecoration: "none",
                        fontWeight: "bold",
                      })}
                      to={`/order/${order?.id}/tracking`}
                    >
                      <Button
                        className={css({
                          color: "#0c9cee",
                          textDecoration: "none",
                          fontWeight: "bold",
                        })}
                        kind="tertiary"
                      >
                        Track this order
                      </Button>
                    </Link>
                    <hr />
                  </>
                )}
                <section
                  className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  })}
                >
                  <section>
                    <Label2>Pickup Contact</Label2>
                    {order?.order_origins && (
                      <ParagraphSmall>
                        Name: {order?.order_origins[0]?.contact_name}
                      </ParagraphSmall>
                    )}
                    {order?.order_origins && (
                      <ParagraphSmall>
                        Phone: {order?.order_origins[0]?.contact_phone}
                      </ParagraphSmall>
                    )}
                    {order?.order_origins && (
                      <ParagraphSmall>
                        Email: {order?.order_origins[0]?.contact_email}
                      </ParagraphSmall>
                    )}
                  </section>
                  <section>
                    <Label2>Dropoff Contact</Label2>
                    {order?.order_destinations && (
                      <ParagraphSmall>
                        Name: {order?.order_destinations[0]?.contact_name}
                      </ParagraphSmall>
                    )}
                    {order?.order_destinations && (
                      <ParagraphSmall>
                        Phone: {order?.order_destinations[0]?.contact_phone}
                      </ParagraphSmall>
                    )}
                    {order?.order_destinations && (
                      <ParagraphSmall>
                        Email:{" "}
                        {order?.order_destinations[0]?.contact_email || "N/A"}
                      </ParagraphSmall>
                    )}
                  </section>
                </section>
              </Tab>
              <Tab title="Assignments">
                <OrderAssignments/>
              </Tab>
              <Tab title="Uploads">
                {/*<OrderHistory />*/}
                <OrderUploads />
              </Tab>
            </Tabs>
          </>
        )}
      </OrderDetails>
    </DetailsContainer>
  );
}
