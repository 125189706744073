// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const VehicleReducer = (
  state: any = {
    isLoading: false,
    message: "",
    vehicles: [],
    detail: "",
    clear: false,
    vehicles_count:{}
  },
  action: any
) => {
  switch (action.type) {
    case TYPES.VH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.VH_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        details: action.payload?.detail,
      };
    case TYPES.FETCH_VEHICLES:
      return {
        ...state,
        isLoading: false,
        vehicles: action.payload.vehicles.items,
        sort: action.sort,
        dir: action.dir,
        per_page: action.payload.vehicles.per_page,
        total:
          Math.round(
            action.payload.vehicles.total / action.payload.vehicles.paginate
          ) + 1,
        page: action.payload.vehicles.page,
        clear: false,
      };
    case "VEHICLE_STATUS_UPDATE":
      return {
        ...state,
        isLoading: false,
      };
    case TYPES.GET_VEHICLE_STATUS_STATISTICS:
      return {
        ...state,
        vehicles_count: action.payload,
      };
    default:
      return state;
  }
};

export default VehicleReducer;
