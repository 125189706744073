/* eslint-disable */

import { FormControl } from "baseui/form-control";
import { H5 } from "baseui/typography";
import { Input } from "baseui/input";
import { DatePicker } from "baseui/datepicker";
import React from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Checkbox } from "baseui/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "baseui/select";
import { Button } from "baseui/button";
import * as CONSTANTS from "../constants";
import { editPrices } from "../products/actions";

function EditPrice({ pricing_type }) {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    current_product: [],
    current_currency: [],
    current_country: [],
    current_date: [],
    cost_base_price: 0,
    cost_unit_price: 0,
    list_base_price: 0,
    list_unit_price: 0,
    is_active: false,
    min_distance: 0,
    max_distance: 0,
    weight_unit: [],
    weight: 0,
    distance_unit: [],
  });

  const [payload, setPayload] = React.useState({
    product_id: null,
    uom_id: 3,
    currency_code: "KES",
    country_code: "KE",
    start_date: null,
    end_date: null,
    cost_base_price: 0,
    cost_unit_price: 0,
    list_base_price: 0,
    list_unit_price: 0,
    is_active: false,
    type_id: pricing_type,
    min_distance: 0,
    max_distance: 0,
    weight_unit: "",
    weight: 0,
    distance_unit: "",
    base_distance: 0,
  });

  const { saving, close, products, currencies, countries, price } = useSelector(
    (state) => state.product
  );

  function onSubmit() {
    dispatch(
      editPrices(
        {
          ...payload,
          start_date: moment(state.current_date[0] || new Date()).format("L"),
          end_date: moment(state.current_date[1] || new Date()).format("L"),
          type_id: pricing_type,
        },
        price.id
      )
    );
  }

  function setData(key, value) {
    setPayload((prev) => ({ ...prev, [key]: value }));
  }

  const { handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  React.useEffect(() => {
    if (price.id) {
      setState((prev) => ({
        ...prev,
        current_currency: [
          { id: price.currency_code, label: price.currency?.code },
        ],
        current_date: [new Date(price.start_date), new Date(price.end_date)],
        current_country: [
          { id: price.country_code, label: price.country?.country_name },
        ],
        current_product: [{ id: price.product_id }],
        list_base_price: parseInt(price.list_base_price, 10),
        list_unit_price: parseInt(price.list_unit_price, 10),
        cost_base_price: parseInt(price.cost_base_price, 10),
        cost_unit_price: parseInt(price.cost_unit_price, 10),
        is_active: price.is_active,
        type: [{ label: price.type_id, id: "price.type_id" }],
        min_distance: price.min_distance,
        max_distance: price.max_distance,
        weight: price.weight,
        weight_unit: [
          {
            label: price.weight_unit,
            id: price.weight_unit,
          },
        ],
        distance_unit: [
          { label: price.distance_unit, id: price.distance_unit },
        ],
        base_distance: price.base_distance,
      }));
      setPayload((prev) => ({
        ...prev,
        product_id: price.product_id,
        type_id: "standard",
        currency_code: price.currency_code,
        country_code: price.country_code,
        start_date: moment(price.start_date || new Date()).format("L"),
        end_date: moment(price.end_date || new Date()).format("L"),
        list_base_price: parseInt(price.list_base_price, 10),
        list_unit_price: parseInt(price.list_unit_price, 10),
        cost_base_price: parseInt(price.cost_base_price, 10),
        cost_unit_price: parseInt(price.cost_unit_price, 10),
        is_active: price.is_active,
        type_id: price.type_id,
        min_distance: price.min_distance,
        max_distance: price.max_distance,
        weight: price.weight,
        weight_unit: price.weight_unit,
        distance_unit: price.distance_unit,
        base_distance: price.base_distance,
      }));
    }
  }, [price]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <H5>Create Price</H5>
        <FormControl label="Product">
          <Select
            options={
              products &&
              products.map((item) => {
                return {
                  id: item.id,
                  label: item.display_name,
                };
              })
            }
            labelKey="label"
            valueKey="id"
            placeholder="Product Name"
            maxDropdownHeight="300px"
            type="select"
            onChange={({ value }) => {
              setData("product_id", value[0] && value[0]?.id);
              setState((prev) => ({
                ...prev,
                current_product: value,
              }));
            }}
            value={state.current_product}
            size="default"
            searchable={true}
          />
        </FormControl>
        {pricing_type !== "standard" && (
          <>
            <FormControl label="Weight">
              <Input
                type="number"
                value={state.weight}
                onChange={(e) => {
                  setData("weight", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    weight: BASE,
                  }));
                }}
              />
            </FormControl>
            <FormControl label="Weight Unit">
              <Select
                options={CONSTANTS.WEIGHT}
                labelKey="label"
                valueKey="id"
                placeholder="Weight Unit"
                maxDropdownHeight="300px"
                type="select"
                onChange={({ value }) => {
                  setData("weight_unit", value[0] && value[0]?.id);
                  setState((prev) => ({
                    ...prev,
                    weight_unit: value,
                  }));
                }}
                value={state.weight_unit}
                size="default"
              />
            </FormControl>
          </>
        )}
        {pricing_type !== "business" && (
          <FormControl label="Distance Unit">
            <Select
              options={CONSTANTS.DISTANCE}
              labelKey="label"
              valueKey="id"
              placeholder="Distance Unit"
              maxDropdownHeight="300px"
              type="select"
              onChange={({ value }) => {
                setData("distance_unit", value[0] && value[0]?.id);
                setState((prev) => ({
                  ...prev,
                  distance_unit: value,
                }));
              }}
              value={state.distance_unit}
              size="default"
            />
          </FormControl>
        )}
        <FormControl label="Start Date - End Date">
          <DatePicker
            range
            value={state.current_date}
            onChange={({ date }) =>
              setState((prev) => ({
                ...prev,
                current_date: date,
              }))
            }
            placeholder="YYYY/MM/DD - YYYY/MM/DD"
            overrides={{
              CalendarHeader: {
                style: /* istanbul ignore next */ ({ $theme }) => ({
                  zIndex: "10000",
                }),
              },
            }}
          />
        </FormControl>
        <FormControl label="Base Distance">
          <Input
            type="number"
            value={state.base_distance}
            onChange={(e) => {
              setData("base_distance", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                base_distance: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="Minimum Distance">
          <Input
            type="number"
            value={state.min_distance}
            onChange={(e) => {
              setData("min_distance", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                min_distance: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="Maximum Distance">
          <Input
            type="number"
            value={state.max_distance}
            onChange={(e) => {
              setData("max_distance", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                max_distance: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="Cost Base Price">
          <Input
            type="number"
            value={state.cost_base_price}
            onChange={(e) => {
              setData("cost_base_price", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                cost_base_price: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="Cost Unit Price">
          <Input
            type="number"
            value={state.cost_unit_price}
            onChange={(e) => {
              setData("cost_unit_price", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                cost_unit_price: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="List Base Price">
          <Input
            type="number"
            value={state.list_base_price}
            onChange={(e) => {
              setData("list_base_price", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                list_base_price: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="List Unit Price">
          <Input
            type="number"
            value={state.list_unit_price}
            onChange={(e) => {
              setData("list_unit_price", e.target.value);
              const BASE = e.target.value;
              setState((prev) => ({
                ...prev,
                list_unit_price: BASE,
              }));
            }}
          />
        </FormControl>
        <FormControl label="Is Active">
          <Checkbox
            checked={state.is_active}
            onChange={(e) => {
              const BASE = e.target.checked;
              setState((prev) => ({
                ...prev,
                is_active: BASE,
              }));
              setData("is_active", BASE);
            }}
            labelPlacement={"right"}
          >
            {state.is_active ? "Deactivate " : "Activate "} price
          </Checkbox>
        </FormControl>
        <Button isLoading={saving} type="submit">
          Update
        </Button>
      </form>
    </>
  );
}

export default EditPrice;
