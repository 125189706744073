// @flow

import * as TYPES from "./constants";
import { fetchVehicles, getVehicleStatusStatistics } from "./service";

export const fetchVehiclesActions =
  (
    page?: number = 1,
    limit?: number = 50,
    sort?: string = "",
    search?: string = ""
  ) =>
  async (dispatch: Function) => {
    dispatch({
      type: TYPES.VH_LOADING,
    });

    fetchVehicles(page, limit, sort, search)
      .then((res) => {
        dispatch({
          type: TYPES.FETCH_VEHICLES,
          payload: res,
          sort: "",
          dir: "",
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.VH_ERROR,
          payload: err.response.data,
        });
      });
  };

export const fetchVehicleCount = () => async (dispatch) => {
  getVehicleStatusStatistics().then((res) => {
    dispatch({
      type: TYPES.GET_VEHICLE_STATUS_STATISTICS,
      payload: res,
    });
  });
};
