/* eslint-disable baseui/no-deep-imports */
/* eslint-disable no-restricted-globals */
import React from "react";
import { Label3, LabelXSmall } from "baseui/typography";
import { Button } from "baseui/button";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Select } from "baseui/select";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import {cancelOrder, updateOrderStatus} from "./actions";
import {
  Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE
} from 'baseui/modal'
import {KIND as ButtonKind} from "baseui/button";

export default function OrderStatus() {
  let { operators } = useSelector((state) => state.operators);
  let { vehicles } = useSelector((state) => state.vehicles);
  const [value, setValue] = React.useState("driver");
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { order, updating } = useSelector((state) => state.order);
  const [driverSelect, setDriverSelect] = useState([]);
  const [vehicleSelect, setVehicleSelect] = useState([]);

  function assignDriver() {
    const option = confirm(
      `Are you sure you want to assign ${driverSelect[0].label} to this order`
    );

    if (option) {
      dispatch(
        updateOrderStatus(order.id, "assign-driver", {
          order_id: order.id,
          driver_id: driverSelect[0].id,
        })
      );
    }
  }

  function assignVehicle() {
    const option = confirm(
      `Are you sure you want to assign ${vehicleSelect[0].label} to this order`
    );

    if (option) {
      dispatch(
        updateOrderStatus(order.id, "confirm-carrier", {
          order_id: order.id,
          vehicle_id: vehicleSelect[0].id,
        })
      );
    }
  }

  function dispatchOrder() {
    const option = confirm(`Are you sure you want to dispatch this order`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "dispatch", {}));
    }
  }

  function cancelledOrder() {
    dispatch(cancelOrder(order.id, "cancel", {}));
    setIsOpen(false)
  }

  function inTransitOrder() {
    const option = confirm(`Are you sure this order is currently in transit?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "in-transit", {}));
    }
  }

  function arrivedToOrigin() {
    const option = confirm(`Are you sure this order is headed to the origin?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "arrive-origin"));
    }
  }

  function orderAtDestination() {
    const option = confirm(`Are you sure the order is at destination?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "arrive-destination"));
    }
  }

  function completeOrder() {
    const option = confirm(`Are you sure the order is complete?`);

    if (option) {
      dispatch(updateOrderStatus(order.id, "complete"));
    }
  }

  return (
    <>
      {/*Modal start*/}
      <Modal
          onClose={() => setIsOpen(false)}
          closeable
          isOpen={isOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
      >
        <ModalHeader>Attention Required</ModalHeader>
        <ModalBody>
          You are about to cancel this order. Are you sure you want to proceed?
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.primary} style={{backgroundColor: '#ffa020', colors: '#ffffff'}}>
            No
          </ModalButton>
          <ModalButton kind={ButtonKind.secondary} onClick={cancelledOrder} style={{

          }}>Yes</ModalButton>
        </ModalFooter>
      </Modal>
      <Label3>Order Status Update</Label3>
      <br />
      <RadioGroup
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        name="number"
        align={ALIGN.horizontal}
      >
        <Radio value="driver" description="Assign by driver">
          By Driver
        </Radio>
        <Radio value="vehicle" description="Assign by vehicle">
          By vehicle
        </Radio>
      </RadioGroup>
      {value === "driver" && (
        <section>
          <LabelXSmall>Assign Driver</LabelXSmall>
          <Select
            options={
              operators &&
              operators
                .filter(
                  (item) =>
                    item.status_id === "available" ||
                    item.status_id === "active" ||
                    item.status_id === "engaged"
                )
                .map((item) => {
                  return {
                    id: item.id,
                    label: item.name,
                  };
                })
            }
            labelKey="label"
            valueKey={"id"}
            multi={false}
            onChange={(e) => {
              setDriverSelect((prev) => [...e.value]);
            }}
            value={driverSelect}
          />
          <br />
          <Button
            onClick={assignDriver}
            isLoading={updating}
            disabled={driverSelect.length > 0 ? false : true}
          >
            Assign Driver
          </Button>
        </section>
      )}

      {value === "vehicle" && (
        <section>
          <LabelXSmall>Assign Vehicle</LabelXSmall>
          <Select
            options={
              vehicles &&
              vehicles
                .filter(
                  (item) =>
                    item.status_id === "available" ||
                    item.status_id === "active" ||
                    item.status_id === "engaged"
                )
                .map((item) => {
                  return {
                    id: item.id,
                    label: item.licence_plate,
                  };
                })
            }
            labelKey="label"
            valueKey={"id"}
            multi={false}
            onChange={(e) => {
              setVehicleSelect((prev) => [...e.value]);
            }}
            value={vehicleSelect}
          />
          <br />
          <Button
            onClick={assignVehicle}
            isLoading={updating}
            disabled={vehicleSelect.length > 0 ? false : true}
          >
            Assign Vehicle
          </Button>
        </section>
      )}

      {order.order_status_id === "in_transit_to_pickup" && (
        <section>
          <br />
          <Button kind="secondary" onClick={arrivedToOrigin}>
            At Pickup
          </Button>
        </section>
      )}
      {(order.order_status_id === "driver_assigned" ||
        order.order_status_id === "carrier_confirmed") && (
        <section>
          <br />
          <Button kind="secondary" onClick={dispatchOrder} style={{
            backgroundColor: '#ffa020',
            color: '#ffffff'
          }}>
            Dispatch
          </Button>

          <Button kind="secondary" onClick={() => {
            setIsOpen(!isOpen)
          }}
                  style={{
                    marginLeft: '12px'
                  }}
          >
            Cancel
          </Button>
        </section>
      )}
      {order.order_status_id === "at_origin" && (
        <section>
          <br />
          <Button kind="secondary" onClick={inTransitOrder}>
            Set Order To Transit
          </Button>
        </section>
      )}
      {order.order_status_id === "in_transit" && (
        <section>
          <br />
          <Button kind="secondary" onClick={orderAtDestination}>
            Order At Dropoff
          </Button>
        </section>
      )}
      {order.order_status_id === "at_destination" && (
        <section>
          <br />
          <Button kind="secondary" onClick={completeOrder}>
            Complete
          </Button>
        </section>
      )}
    </>
  );
}
