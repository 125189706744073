/* eslint-disable */

import { useStyletron } from "baseui";
import React, { useEffect, useState } from "react";
import ReactMapGL, {
  FlyToInterpolator,
  Marker,
  Popup,
  Source,
  Layer,
} from "react-map-gl";
import { Label3, ParagraphXSmall } from "baseui/typography";
// import d3 from "d3-ease";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllPostionsActions, getDevicesActions } from "./actions";
// import { bearingCalc } from "../../helpers/location";
/* istanbul ignore next */
import "mapbox-gl/dist/mapbox-gl.css";
// istanbul ignore next
import mapboxgl from "mapbox-gl";
import ControlPanel from "./control";
import { useRouting } from "./geocoding";
import OrderPopupContainer from "./order-popups";
import assetMapping from "../asset-mapping";
import { io } from "socket.io-client";
import {
  quatrixMobileTraccar,
  quatrixTraccarUrl,
  traccarToken,
} from "../config";

const {
  mobileTraccarURL,
  traccarURL,
  websocketMobileTraccarURL,
  websocketTraccarURL,
} = assetMapping;

/* istanbul ignore next */
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function Tracking(props) {
  const [css] = useStyletron();
  const [viewPort, setViewPort] = useState({
    width: "100vw",
    height: "100%",
    latitude: 0.1769,
    longitude: 37.9083,
    zoom: 12,
    show: false,
  });
  const [device, setDevices] = useState(null);
  const [position, setPosition] = useState(null);
  const [trail, setTrail] = useState([]);
  const [bearing, setBearing] = useState(0);
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lon: null,
  });
  const [geojson, setGeoJSON] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      },
    ],
  });
  const [distanceTracker, setDistanceTracker] = useState([0, 0]);
  const [isOpen, setIsOpen] = useState(true);
  const [popup, setPopup] = useState({
    popup: false,
    type: null,
  });

  const layerStyle = {
    id: "FeatureCollection",
    type: "line",
    source: "FeatureCollection",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#dd3e3b",
      "line-width": 8,
      "line-opacity": 1,
    },
  };

  const routeStyle = {
    id: "RouteCollection",
    type: "line",
    source: "FeatureCollection",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#000000",
      "line-width": 4,
      "line-opacity": 1,
    },
  };

  const dispatch = useDispatch();

  const pontyURL = "https://traccar.pontypriddholdings.com";
  const quatrixURL = traccarURL;
  const quatrixMobileURL = mobileTraccarURL;
  const pontyToken = "kMjnf5pvecqb7bFS70voa4v8iVkJRMyN";
  const quatrixToken = traccarToken;

  function realtimeUpdates(isBike) {
    var url = isBike ? websocketMobileTraccarURL : websocketTraccarURL;

    console.log(url);

    const socket = io(url, {
      transports: ["websocket", "polling"],
    });

    socket.on("connect_error", () => {
      socket.io.opts.transports = ["websocket", "polling"];
    });

    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("positions", (event) => {
      const data = JSON.parse(event);

      dispatch({
        type: "LIST_POSITION",
        payload: data,
        single: false,
        recall: true,
      });
    });
  }

  const { vehicle, points, order, isBike } = props;

  const [path] = useRouting({
    pickup: currentLocation.lat ? currentLocation : points.pickup,
    dropoff: points.dropoff,
    position,
  });

  useEffect(() => {
    if (order && order.id) {
      realtimeUpdates(isBike);
    }
  }, [order, isBike]);

  useEffect(() => {
    if (order && order.id) {
      dispatch(getDevicesActions(isBike));
      dispatch(getAllPostionsActions(isBike));
    }
  }, [order]);

  const { devices, positions, isLoading } = useSelector(
    (state) => state.order_tracking
  );

  useEffect(() => {
    if (vehicle?.licence_plate && devices?.length > 0) {
      const _device = devices.find(
        (item) => item.name === vehicle.licence_plate.replace(/\s/g, "")
      );

      if (_device.id) {
        setDevices(() => _device);
      }
    }
  }, [devices, vehicle]);

  useEffect(() => {
    if (device && device.name && positions && positions.length > 0) {
      const _position = positions.find((item) => item.deviceId === device.id);
      if (_position) {
        setPosition(() => _position);
        setTrail((prev) => [
          ...prev,
          [_position?.longitude, _position?.latitude],
        ]);

        const pastDistance =
          distanceTracker.length >= 4
            ? distanceTracker.slice(1, 3)
            : distanceTracker;

        setDistanceTracker((prev) => [
          ...pastDistance,
          _position.attributes?.distance,
        ]);
        setBearing(_position.course);
      }
    }
  }, [device, positions]);

  useEffect(() => {
    setGeoJSON((prev) => ({
      ...prev,
      ...{
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [...trail],
            },
          },
        ],
      },
    }));
  }, [trail]);

  useEffect(() => {
    if (position) {
      setViewPort((prev) => ({
        ...prev,
        latitude: position.latitude,
        longitude: position.longitude,
        show: true,
      }));
      setCurrentLocation((prev) => ({
        ...prev,
        lat: position.latitude,
        lon: position.longitude,
      }));
    }
  }, [position]);

  function toggleDetails(type) {
    setPopup((prev) => ({
      ...prev,
      type,
      popup: type !== popup.popup ? true : false,
    }));
  }

  const markers = React.useMemo(() => (
    <>
      {position && (
        <Marker
          key={position.address}
          longitude={position.longitude}
          latitude={position.latitude}
          draggable={false}
        >
          <div style={{ transform: `translate(${-20 / 2}px, ${-20}px)` }}>
            <img
              src={isBike ? "/pin.png" : "/trck.svg"}
              alt="marker"
              style={{
                transform: `rotate(${bearing}deg)`,
                width: "20px",
                // transform: `translate(-10px, -20px)`,
              }}
            />
          </div>
        </Marker>
      )}
      {points.pickup.lon && points.pickup.lat && points.pickup.address && (
        <Marker
          key={`111`}
          longitude={Number(points.pickup.lon)}
          latitude={Number(points.pickup.lat)}
          draggable={false}
          captureClick={true}
        >
          <section
            className="mkcontainer"
            onClick={() => toggleDetails("pickup")}
          >
            <img
              className="beacon pikcup"
              src="/destination.svg"
              alt=""
              style={{
                width: "20px",
                // transform: `translate(-10px, -20px)`,
              }}
            />
          </section>
        </Marker>
      )}
      {points.dropoff.lon && points.dropoff.lat && points.dropoff.address && (
        <Marker
          key={`222`}
          longitude={Number(points.dropoff.lon)}
          latitude={Number(points.dropoff.lat)}
          draggable={false}
        >
          <section
            className="mkcontainer"
            onClick={() => toggleDetails("dropoff")}
          >
            <img
              className="beacon dropoff"
              src="/dropoff.svg"
              alt=""
              style={{
                width: "20px",
                // transform: `translate(-10px, -20px)`,
              }}
            />
          </section>
        </Marker>
      )}
    </>
  ));

  function resetPosition() {
    if (position) {
      setViewPort((prev) => ({
        ...prev,
        latitude: position.latitude,
        longitude: position.longitude,
        zoom: 14,
        show: true,
      }));
      setCurrentLocation((prev) => ({
        ...prev,
        lat: position.latitude,
        lon: position.longitude,
      }));
    }
  }

  function togglePopup() {
    setIsOpen(!isOpen);
  }

  return (
    <section
      className={css({
        width: "100%",
        maxWidth: "1280px",
        height: "86vh",
      })}
    >
      {device && devices?.length > 0 ? (
        <>
          {popup.popup && (
            <OrderPopupContainer
              type={popup.type}
              show={popup.popup}
              order={order}
            />
          )}
          <ReactMapGL
            {...viewPort}
            onViewportChange={setViewPort}
            transitionInterpolator={new FlyToInterpolator()}
            mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
            mapboxApiAccessToken="pk.eyJ1IjoicXVhdHJpeGdsb2JhbCIsImEiOiJja2ZpNHVhM2gwZXltMnBxdGdqbWtrdmkyIn0.T2QUY4ugqoJ6r9yO_I6KRw"
          >
            {markers}
            {isOpen && device && position?.id && (
              <Popup
                latitude={position?.latitude || viewPort.latitude}
                longitude={position?.longitude || viewPort.longitude}
                anchor="bottom"
                closeButton={true}
                closeOnClick={false}
                onClose={togglePopup}
                className={css({
                  transform: "translate(0px, -100px)",
                })}
              >
                <div
                  className={css({
                    padding: "4px",
                    background: "#ffffff",
                  })}
                >
                  {position?.address && (
                    <ParagraphXSmall
                      className={css({
                        margin: "0px",
                        fontSize: "12px",
                        color: "#f08c00",
                        fontWeight: "bold",
                      })}
                    >
                      {device?.name}
                    </ParagraphXSmall>
                  )}
                  {position?.id && (
                    <ParagraphXSmall
                      className={css({
                        margin: "0px",
                        fontSize: "12px",
                      })}
                    >
                      Driver: {order?.driver?.name}
                      <br />
                      Phone: {order?.driver?.phone}
                      <br />
                      Email: {order?.driver?.email}
                      <br />
                      Location: {position?.address.split(",")[0]}
                    </ParagraphXSmall>
                  )}
                </div>
              </Popup>
            )}
            {/* <Source id="FeatureCollection" type="geojson" data={geojson}>
              <Layer {...layerStyle} />
            </Source> */}
            {path.coordinates && path.coordinates?.length > 0 && (
              <Source id="RouteCollection" type="geojson" data={path}>
                <Layer {...routeStyle} />
              </Source>
            )}
            <ControlPanel
              resetPosition={resetPosition}
              togglePopup={togglePopup}
              orderId={order.id}
            />
          </ReactMapGL>
        </>
      ) : (
        <>
          <Label3
            style={{
              fontWeight: "bold",
              color: "#21bf73",
              margin: "0px",
            }}
          >
            Loading GPS data
          </Label3>
        </>
      )}
    </section>
  );
}
