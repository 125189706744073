/* eslint-disable baseui/deprecated-component-api */
import React from "react";
import { useStyletron } from "baseui";
import { Spinner } from "baseui/spinner";

const Loader = ({ small }): React.ReactElement => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        placeItems: "center",
        justifyContent: small ? "left" : "center",
        height: small ? "" : "80vh",
      })}
    >
      <Spinner size={small ? 24 : 64} color="#ffa41b" />
    </div>
  );
};

export default Loader;
