// @flow

import axios from "axios";
import { baseURL } from "../config";

export const fetchVehicles = async (page, limit, sort, search) => {
  const url = `${baseURL}/api/admin/vehicles?page=${page}&paginate=${limit}&sort=${sort}${search}`;

  try {
    let response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getVehicleStatusStatistics=async ()=>{
  try {
    let response=await axios.get(`${baseURL}/api/admin/statistics/vehicles/status/count`);
    return response.data;
  } 
  catch(error){
    throw error;
  }
}