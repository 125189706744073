// @flow

import * as TYPES from "./constants";
import { fetchCarriers, getStatuses, sortCarriers,getCarrierStatusStatistics, getDrivers } from "./service";

export const fetchCarriersAction = (
  id: number = null,
  page?: number = 1,
  limit?: number = 700,
  sort?: string = "",
  condition?: string = "is_operator=true",
  search?: string | null = null) => async (dispatch: Function) => {
  dispatch({
    type: TYPES.OPS_LOADING,
  });

  fetchCarriers(id, page, limit, sort, condition, search)
    .then((res) => {
      dispatch({
        type: TYPES.FETCH_CARRIERS,
        payload: res,
        sort: "",
        dir: "",
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.OPS_ERROR,
        payload: err.response.data,
      });
    });
};

export const fetchCarrierWithSort = (sort: string, dir: string) => async (
  dispatch: Function
) => {
  sortCarriers(sort, dir)
    .then((res) => {
      dispatch({
        type: TYPES.FETCH_CARRIERS,
        payload: res,
        sort,
        dir,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.OPS_ERROR,
        payload: err.response.data,
      });
    });
};

export const fetchStatuses = () => async (dispatch) => {
  getStatuses()
    .then((res) => {
      dispatch({
        type: TYPES.GET_STATUSES,
        payload: res,
      });
    })
};

export const fetchCarrierCount=()=>async (dispatch)=>{

  getCarrierStatusStatistics()
    .then((res)=>{
      dispatch({
        type: TYPES.GET_CARRIER_STATUS_STATISTICS,
        payload: res,
      });
    }
    )
};

export const fetchDrivers=()=>async (dispatch)=>{

  getDrivers()
    .then((res)=>{
      dispatch({
        type:TYPES.GET_DRIVER_COUNT,
        payload:res.count
      });
    })
};