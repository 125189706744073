import { useStyletron } from "baseui";
import { Paragraph3 } from "baseui/typography";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../qui/loader";
import { fetchSingleOrderAction, syncOrder } from "../orders/actions";
import { capitalizer } from "../helpers/string";
import Tracking from "./tracking";
import withNav from "../hoc/with-nav";

function OrderTrackingPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const [css] = useStyletron();
  const [points, setPoints] = useState({
    pickup: {
      lon: null,
      lat: null,
      address: null,
    },
    dropoff: {
      lon: null,
      lat: null,
      address: null,
    },
  });

  const { order, sideLoading } = useSelector((state) => state.order);

  const {
    vehicle,
    order_status_id,
    order_origins,
    order_destinations,
    order_lines,
  } = order;

  useEffect(() => {
    if (params.order_id) {
      dispatch(fetchSingleOrderAction(params.order_id));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(syncOrder(params.order_id));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (order_origins && order_destinations) {
      setPoints((prev) => ({
        ...prev,
        pickup: {
          ...points.pickup,
          lon: order_origins[0].location_longitude,
          lat: order_origins[0].location_latitude,
          address: order_origins[0].location_name,
        },
        dropoff: {
          ...points.dropoff,
          lon: order_destinations[0].location_longitude,
          lat: order_destinations[0].location_latitude,
          address: order_destinations[0].location_name,
        },
      }));
    }
  }, [order_origins, order_destinations]);

  return (
    <section
      className={css({
        width: "auto",
      })}
    >
      {sideLoading ? (
        <section
          className={css({
            display: "flex",
            justifyContent: "center",
            placeItems: "center",
          })}
        >
          <Loader />
        </section>
      ) : (
        <section
          className={css({
            width: "auto",
            height: "100%",
          })}
        >
          {order_status_id &&
          (order_status_id !== "created" ||
            order_status_id !== "matching_carriers" ||
            order_status_id !== "cancelled") ? (
            <Tracking
              vehicle={vehicle}
              points={points}
              order={order}
              isBike={order_lines[0]?.name === "Motorcycle"}
            />
          ) : (
            <section
              className={css({
                display: "flex",
                justifyContent: "center",
                placeItems: "center",
                height: "100%",
              })}
            >
              <Paragraph3>
                Tracking unavailable, the order is{" "}
                {capitalizer(order_status_id)}
              </Paragraph3>
            </section>
          )}
        </section>
      )}
    </section>
  );
}

export const OrderTracking = withNav(OrderTrackingPage, false, true);
