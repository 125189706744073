import { Label4, Paragraph3 } from "baseui/typography";
import React from "react";
import { useStyletron } from "styletron-react";
import moment from "moment";

type Props = {
  price: any,
};

const Details: React.FC<Props> = (props): React.ReactElement => {
  const [css] = useStyletron();

  return (
    <div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>Country:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          {props.price.country_code && props.price.country_code}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>Currency:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          {props.price.currency_code && props.price.currency_code}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>Type:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          {props.price.type_id}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>Start Date:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          {moment(props.price.start_date).format("MMM Do YY")}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>End Date:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          {moment(props.price.end_date).format("MMM Do YY")}
        </Paragraph3>
      </div>
      <hr />
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>Cost Base Price:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          KES.{props.price.cost_base_price}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>Cost Unit Price:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          KES.{props.price.cost_unit_price}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>List Base Price:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          KES.{props.price.list_base_price}
        </Paragraph3>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <Label4>List Unit Price:</Label4>
        <Paragraph3 $style={{ marginLeft: "16px" }}>
          KES.{props.price.list_unit_price}
        </Paragraph3>
      </div>
    </div>
  );
};

export default Details;
