import { useStyletron } from "baseui";
import React, { useEffect } from "react";
import { DatePicker } from "baseui/datepicker";
import { Label1 } from "baseui/typography";
import { useReport } from "../context/report";
import { useState } from "react";
import { Select } from "baseui/select";

export default function SpeedReport() {
  const [css] = useStyletron();
  const { devices, filteredDevice, setDate, setFilteredDevice } = useReport();
  const [rangeDate, setRangeDate] = useState(null);

  useEffect(() => {
    if (rangeDate) {
      const from = new Date(rangeDate);

      const to = new Date(rangeDate);

      from.setHours(0, 0, 0, 0);

      to.setHours(23, 59, 29, 999);

      setDate(from, to);
    }
  }, [rangeDate]);

  return (
    <section
      className={css({
        marginTop: "160px",
        "@media (max-width: 1133)": {
          marginTop: "16px",
        },
      })}
    >
      <Label1>Ponty Speed Reports Generation</Label1>
      <section>
        <Select
          options={devices.map((item) => {
            return {
              label: item.name,
              id: item.name,
            };
          })}
          labelKey="label"
          valueKey="id"
          value={filteredDevice}
          onChange={(params) => setFilteredDevice(() => params.value)}
        />
        <br />
        <DatePicker
          value={rangeDate}
          onChange={({ date }) => setRangeDate(() => date)}
        />
      </section>
    </section>
  );
}
